import TicketService from '../services/ticket.service'
import CustomerService from '../services/customer.service'
import i18n from '@/i18n.js'
export const ticket = {
    namespaced: true,
    state: () => ({
        groups: [],
        admins: [],
        admin_data: [],

        // ticket subject
        subjects: [],
        // tickets
        tickest_admin: [],
        tickest_customer: []
    }),
    actions: {
        // admin group
        getGroupsAdmin({ state, rootState }) {
            return TicketService.get_group_admin().then(
                res => {
                    // //console.log(res)
                    state.groups = res.data.groups
                    // for (let i = 0; i < state.groups.length; i++) {
                    //     const element = state.groups[i];
                    //     state.admin_data[element.id] = element.admins
                    // }
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addGroupAdmin({ rootState, state }, data) {
            return TicketService.add_group_admin(data.data).then(
                res => {
                    // //console.log(res)
                    state.groups = state.groups.concat(res.data.group)
                    state.admin_data[res.data.group.id] = res.data.group.admins
                    state.admins = []
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        UpdateGroupAdmin({ rootState, state }, data) {
            return TicketService.edit_group_admin(data.id, data.data).then(
                res => {
                    // //console.log(res)
                    state.groups = state.groups.filter(v => v.id != data.id)
                    state.groups = state.groups.concat(res.data.group)
                    state.admin_data[res.data.group.id] = res.data.group.admins
                    state.admins = []
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyGroupAdmin({ state }, id) {
            return TicketService.destroy_group_admin(id).then(
                res => {
                    // console.log(response)
                    state.groups = state.groups.filter(v => v.id != id)
                    state.admins = state.admins.splice(id, 1);
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        destroyAdmin({ state }, data) {
            //console.log(data)
            return TicketService.destroy_admin_from_group(data).then(
                res => {
                    // //console.log(res)
                    state.admins = state.admins.filter(v => v.id != data.admin_id)
                    state.groups.forEach(function (v, index) {
                        // console.log(v)
                        // console.log(index)
                        if (v.id == data.ticket_admin_group_id) {
                            state.groups[index].admins = v.admins.filter(m => m.id != data.admin_id)
                        }
                    }
                    )
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        getAdmins({ state }, id) {
            state.groups.forEach(function (v) {
                if (v.id == id) {
                    state.admins = v.admins
                }
            })
        },


        // ticket subject
        getTicketSubject({ state, rootState }, data) {
            return TicketService.get_ticket_subject(data.page, data.data).then(
                res => {
                    // //console.log(res)
                    state.subjects = res.data.subjects.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },
        addTicketSubject({ rootState, state }, data) {
            return TicketService.add_ticket_subject(data.data).then(
                res => {
                    // //console.log(res)
                    state.subjects = state.subjects.concat(res.data.subject)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editTicketSubject({ rootState, state }, data) {
            return TicketService.edit_ticket_subject(data.id, data.data).then(
                res => {
                    // //console.log(res)
                    state.subjects = state.subjects.filter(v => v.id != data.id)
                    state.subjects = state.subjects.concat(res.data.subject)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyTicketSubject({ state }, id) {
            return TicketService.destroy_ticket_subject(id).then(
                res => {
                    // console.log(response)
                    state.subjects = state.subjects.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },


        getTicketAdmin() {
            return TicketService.get_ticket_admin().then(
                res => {
                    // //console.log(res)
                    state.tickest_admin = res.data.tickest_admin
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },

        getTicketsCustomer({ state, rootState }, data) {
            //console.log(data)
            return CustomerService.get_ticket_customer(data).then(
                res => {
                    // //console.log(res)
                    state.tickest_customer = res.data.tickets
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },

        addTicket({ rootState, state }, data) {
            const formData = new FormData();
            if (rootState.form.form_data.attachment != '') {
                formData.append("attachment", rootState.form.form_data.attachment);
            }
            formData.append("content", rootState.form.form_data.content);
            formData.append("subject_id", rootState.form.form_data.subject_id);
            return CustomerService.add_ticket(formData).then(
                res => {
                    // //console.log(res)
                    state.tickest_customer = state.tickest_customer.concat(res.data.ticket)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
    },
};