import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import '@mdi/font/css/materialdesignicons.css'
import store from './store'
import i18n from "./i18n";

Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

// Axios setup
import VueAxios from 'vue-axios'


import authHeader from './services/auth-header';
const axios = require('axios')
Vue.use(VueAxios, axios)

window.axios = require('axios')
// let url = 'http://alborg.dohalaw.net/'
let url = 'https://testbackend.wmitproj.com/'
// let url = 'http://127.0.0.1:8000/'
Vue.prototype.$baseURL = url
axios.defaults.baseURL = url + "api/"
axios.interceptors.request.use(function (config) {
    config.headers = authHeader()
    return config
})

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error)
    if (error.response.status == 401) {
        console.log('401')
        store.dispatch("auth/logout")
            .then(() => {

                router.push(localStorage.getItem('login_page'))
            })
            .catch((err) => console.log(err));
    }
    else if (error.response.status == 500) {
        console.log('500')
        if (error.response.data.code && error.response.data.code == '401') {
            console.log('test 401')
        } else {
            // store.dispatch("form/setNotify", {
            //     msg: i18n.t("general.there is problem"),
            //     type: "Danger",
            // });
        }
    }
    return Promise.reject(error);
});

if (localStorage.hasOwnProperty('lang')) {
    var lang = localStorage.getItem("lang")
} else {
    var lang = localStorage.setItem("lang", 'en');
}
document.documentElement.lang = lang
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

import moment from 'moment';
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('Y-m-d hh:mm:ss')
    }
});

Vue.filter('getMonth', function (value) {
    if (value) {
        const date = new Date();
        date.setMonth(value - 1);
        return date.toLocaleString([], {
            month: 'long',
        });
    }
});

const timeoutInMS = 900000; // 15 minutes -> 15 * 60 * 1000
let timeoutId;

function handleInactive() {
    console.log('handel in active')
    store.dispatch("auth/logout")
        .then(() => {

            router.push(localStorage.getItem('login_page'))
        })
        .catch((err) => console.log(err));
    // Here you want to logout a user and/or ping your token
}

function startTimer() {
    // setTimeout returns an ID (can be used to start or clear a timer)
    timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() {
    clearTimeout(timeoutId);
    startTimer();
}

function setupTimers() {
    console.log('step timer')
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);

    startTimer();
}

Vue.prototype.$setupTimers = setupTimers;

require('./assets/css/fonts.css');
require('./assets/css/main.css');

// Auth && Permissions routes setup
router.beforeEach((to, from, next) => {
    // let language = to.params.lang;
    // if (!language) {
    //     language = "en";
    // }

    // i18n.locale = language;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // console.log(to)
        if (store.state.auth.loggedIn) {
            store.dispatch("form/resetNotify");
            store.dispatch("getNotifocations")
            localStorage.removeItem('location')
            if (to.meta.redirect) { // /form
                if (!localStorage.hasOwnProperty("register-form")) {
                    router.go(-1);
                }
                else {
                    localStorage.removeItem("register-form");
                }
            }
            if (to.meta.permissions && to.meta.permissions.length > 0) {
                // console.log(store.state.auth.permissions)
                const isAllowed = store.state.auth.permissions.includes(to.meta.permissions)
                if (!isAllowed) {
                    console.log('is not Allowod')
                    next({
                        name: '403',
                    })
                } else { next() }
            } else {
                if (to.meta.groupName == "Applicant" && store.state.auth.is_admin == 1) {
                    console.log('admin and page customer')
                    next({
                        name: '403',
                    })
                } else {
                    // if (to.meta.groupName == "Applicant" && to.path != '/form' &&

                    //     localStorage.hasOwnProperty("register-form")) {
                    //     alert(3)
                    //     router.push('/form');
                    // } else {
                    //     localStorage.removeItem("register-form");
                    //     next()
                    // }
                    next()
                }

            }
        } else {
            // console.log('to')
            // console.log(to)
            localStorage.setItem('location', to.fullPath)
            if (store.state.auth.is_admin != null) {
                if (store.state.auth.is_admin == 1) {
                    next({ path: '/admin/login' })
                } else {
                    next({ path: '/login' })
                }
            } else {
                // console.log('here logout in1')
                if (to.meta.groupName == 'Applicant') {
                    next({ path: '/login' })
                } else {
                    // console.log('here logout in')
                    next({ path: '/admin/login' })
                }
            }
        }
    } else if (to.matched.some(record => record.meta.isGuest) && store.state.auth.loggedIn) {
        next({
            path: '/',
        })
    } else {
        next()
    }

})


new Vue({
    router,
    vuetify,
    store,
    i18n,
    icons: {
        iconfont: 'mdi',
    },
    render: (h) => h(App),
}).$mount("#app");