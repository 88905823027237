import AuthService from '../services/auth.service';
const user = JSON.parse(localStorage.getItem('user'));
const is_admin = JSON.parse(localStorage.getItem('is_admin'));
const permissions = JSON.parse(localStorage.getItem('permissions'));
// const str_per = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')).toString();
const initialState = user ? { loggedIn: true, user, is_admin, permissions, } : { loggedIn: false, user: null, is_admin: null, permissions: [], };
export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        str_per(state) {
            return state.permissions.toString()
        }
    },
    actions: {
        login({ commit }, user_data) {
            return AuthService.login(user_data.form).then(
                user => {

                    var res = user.data.original
                    if ((user_data.type == "customer" && user.is_admin == 0) || (user_data.type == "admin" && user.is_admin == 1 && res.permissions.length > 0)) {
                        // if (user_data.type == "admin" && user.is_admin == 1 && res.permissions.length > 0) {
                        // }
                        if (res.access_token) {
                            localStorage.setItem('permissions', JSON.stringify(res.permissions));
                            localStorage.setItem('user', JSON.stringify(res.user));
                            localStorage.setItem('accessToken', JSON.stringify(res.access_token));
                            localStorage.setItem('is_admin', JSON.stringify(user.is_admin));

                            var data = { user: res.user, is_admin: user.is_admin }
                            commit('loginSuccess', data);
                            commit('setPermission', res.permissions);
                        }
                    }
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loginGoogle() {
            return AuthService.login_google().then(
                user => {
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        loginGoogleCallback({ commit }, data) {
            // console.log(data)
            return AuthService.login_google_callback(data).then(
                user => {
                    // console.log(user)
                    var data_res = { user: user.data, is_admin: user.is_admin }
                    commit('loginSuccess', data_res);
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        loginFacebook() {
            return AuthService.login_facebook().then(
                user => {
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        loginFacebookCallback({ commit }, data) {
            // console.log(data)
            return AuthService.login_facebook_callback(data).then(
                user => {
                    // console.log(user)
                    var data_res = { user: user.data, is_admin: user.is_admin }
                    commit('loginSuccess', data_res);
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit, state }) {
            var data = { is_admin: state.is_admin }
            AuthService.logout();
            commit('logout');
            return Promise.resolve(data);
        },
        register({ commit }, user) {
            // console.log(user)
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    // alert('registerFailure');
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        setPermission(state, data) {
            // console.log(data)
            state.permissions = data;
        },
        loginSuccess(state, data) {
            // console.log(data)
            state.loggedIn = true;
            state.is_admin = data.is_admin;
            state.user = data.user;
        },
        loginFailure(state) {
            state.loggedIn = false;
            state.is_admin = null;
            state.user = null;
        },
        logout(state) {
            state.loggedIn = false;
            state.user = null;
            state.is_admin = null;
        },
        registerSuccess(state) {
            state.loggedIn = false;
        },
        registerFailure(state) {
            state.loggedIn = false;
        }
    },
};