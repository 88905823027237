class StageService {
  get_stages(page, data) {
    console.log(page)
    return axios.post('stages?page=' + page, data)
  }
  get_stage(id) {
    return axios.get('stages/edit/' + id)
  }
  add_stage(data) {
    return axios.post('stages/store', data)
  }
  edit_stage(id, data) {
    return axios.post('stages/update/' + id, data)
  }
  destroy_stage(id) {
    return axios.delete('stages/delete/' + id)
  }

}
export default new StageService();