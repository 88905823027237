import customerService from '../services/customer.service'
import i18n from '@/i18n.js'
import Vue from "vue";
export const customer = {
    namespaced: true,
    state: () => ({
        customer: {},
        inqueries: [],
        inquiries_data: [],
        inqueries_all: [],

        tab_inqueries: [],
        print_selected: [],
        repeatable_inquiries: {}
    }),
    actions: {

        getCustomerDetails({ state, rootState }, id) {
            // console.log(id)
            return customerService.customer_details(id).then(
                res => {
                    state.customer = res.data.customer;
                    state.inqueries = res.data.inqueries
                    state.repeatable_inquiries = res.data.repeatable_inquiries
                    return Promise.resolve(res);
                },
                error => {
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

        selectInquery({ rootState, state }) {
            var data = state.inqueries.filter(v => v.id == rootState.form.action)
            // console.log(data[0].inputs.type)
            rootState.form.style_form[1].type = data[0].inputs.type
            rootState.form.form_data.type = data[0].inputs.type
        },

        show_hide_repeatble({ rootState }, event) {
            if (event)
                rootState.form.style_form[3].disabled = true
            else
                rootState.form.style_form[3].disabled = false
        },

        saveInquery({ rootState, state }, data) {
            //console.log(data)
            const formData = new FormData();
            formData.append("inquiry_id", data.data.inquiry_id);
            if (data.data.answer != null) {
                formData.append("answer", data.data.answer);
            }
            formData.append("type", data.data.type);
            // console.log(data.data.status)
            if (data.data.status) {
                formData.append("status", "all");
            } else {
                formData.append("status", "only");
                formData.append("id", data.id);
            }

            // if inquery only admin => its not be repeatable
            if (data.data.admins) {
                formData.append("admins", 1);
                formData.append("repeatable", 0);
            } else {
                if (data.data.repeatable) {
                    formData.append("repeatable", 1);
                } else {
                    formData.append("repeatable", 0);
                }
                formData.append("admins", 0);
            }
            return customerService.assign_customer_inquiry(formData).then(
                res => {
                    //console.log(res)
                    if (res.data.code == 500) {
                        rootState.form.loader = false;
                        rootState.form.notify = {
                            msg: res.data.message,
                            type: "Danger",
                        };
                    } else {
                        if (data.data.repeatable == false || (data.data.repeatable == true && data.data.admins == true)) {
                            // have to add all inquery without repeatble
                            state.customer.inquiries = state.customer.inquiries.concat(res.data.inquery)
                        }
                        rootState.form.loader = false;
                        rootState.form.dialog = false;
                        rootState.form.notify = {
                            msg: res.data.message,
                            type: "Success",
                        };
                    }
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },

        updateInquery({ rootState, state }, data) {
            //console.log(data)
            const formData = new FormData();
            formData.append("inquiry_id", data.data.inquiry_id);
            if (data.data.answer != null && data.data.answer != '') {
                formData.append("answer", data.data.answer);
                formData.append("type", data.data.type);
            }
            // console.log(data.data.status)
            if (data.data.status) {
                formData.append("checked", "1");
            } else {
                formData.append("checked", "0");
            }
            return customerService.update_answer_customer_inquiries(data.id, formData).then(
                res => {
                    //console.log(res)
                    state.customer.inquiries = state.customer.inquiries.filter(
                        (v) => v.id != data.id
                    );
                    state.customer.inquiries = state.customer.inquiries.concat(res.data.inquery)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },

        deleteInquery({ rootState, state }, data) {

            //console.log(data)
            Vue.swal({
                title: i18n.t("general.Are you sure?!"),
                text: i18n.t("You won't be able to revert this!"),
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: i18n.t("general.Yes, delete it!"),
                cancelButtonText: i18n.t("general.No, cancel!"),
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    const formData = new FormData();
                    if (data.data.status) {
                        formData.append("checked", "1");
                    } else {
                        formData.append("checked", "0");
                    }
                    return customerService.delete_customer_inquiries(data.id, formData).then(
                        res => {
                            //console.log(res)
                            state.customer.inquiries = state.customer.inquiries.filter(
                                (v) => v.id != data.id
                            );
                            rootState.form.loader = false;
                            rootState.form.dialog = false;
                            Vue.swal.fire("Deleted!", res.data.message, "success");
                            return Promise.resolve(res);
                        },
                        error => {
                            rootState.form.loader = false;

                            Vue.swal.fire(
                                "Error",
                                i18n.t("general.There error please try again"),
                                "error"
                            );

                            return Promise.reject(error);
                        }
                    );
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Vue.swal.DismissReason.cancel
                ) {
                    Vue.swal.fire(
                        i18n.t("general.Cancelled"),
                        i18n.t("general.Cancelled Delete"),
                        "error"
                    );
                }
            });

        },

        Profile({ state, rootState }) {
            return customerService.profile().then(
                res => {
                    state.customer = res.data.customer;
                    state.inqueries = res.data.answer_inquiries;
                    state.repeatable_inquiries = res.data.repeatable_inquiries
                    return Promise.resolve(res);
                },
                error => {
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

        addInquery({ state }) {
            var new_array = [];
            for (let i = 0; i < state.inqueries.length; i++) {
                const element = state.inqueries[i];
                new_array.push({
                    id: element.inquery_id,
                    answer: null,
                    type: element.type,
                });
            }
            state.inquiries_data = new_array
            state.inqueries_all = state.inqueries;
        },

        editInquery({ state }, item) {
            state.inqueries_all = [item];
            let answer = item.type == 'file' ? null : item.answer;
            state.inquiries_data = [
                { id: item.inquery_id, answer: answer, type: item.type, row_id: item.id },
            ];
        },

        updateInqueryInCustomer({ state }, data) {
            return customerService.update_answer_customer_inquiries(data.id, data.data).then(
                (response) => {
                    if (response.data.inquery.repeatable == 0) {

                        state.customer.inquiries = state.customer.inquiries.filter(
                            (v) => v.id != data.id
                        );
                        state.inquiries_data.splice(0, 1);
                        state.customer.inquiries = state.customer.inquiries.concat(response.data.inquery);
                    }

                    return Promise.resolve(response);
                },
                (error) => {
                    //console.log(error);
                    return Promise.reject(error);
                }
            );
        },

        saveInqueryInCustomer({ rootState, state }, data) {
            //console.log(data)
            return customerService.answer_customer_inquiries(data).then(
                (response) => {
                    // console.log(response);
                    for (let i = 0; i < state.inquiries_data.length; i++) {
                        const element = state.inquiries_data[i];
                        //console.log(element);
                        if (element.answer != null) {
                            state.inqueries = state.inqueries.filter(
                                (v) => v.inquery_id != element.id
                            );
                            state.inquiries_data.splice(i, 1);
                            i--;
                        }
                    }
                    state.customer.inquiries = state.customer.inquiries.concat(response.data.inquiries);
                    return Promise.resolve(response);
                },
                (error) => {
                    //console.log(error);
                    return Promise.reject(error);
                }
            );

        },

        getTabsInquery({ state, rootState }) {
            return customerService.get_tabs_inquery().then(
                res => {
                    state.tab_inqueries = res.data.tab_inqueries;
                    state.inqueries = res.data.inqueries
                    return Promise.resolve(res);
                },
                error => {
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

        saveTabInquery({ rootState, state }, data) {
            //console.log(data)
            let formData = new FormData();
            formData.append("name", data.data.tab);
            var is_required = 0;
            for (let i = 0; i < data.data.form_data_inqueries.length; i++) {
                const element = data.data.form_data_inqueries[i];
                if (element.is_required) {
                    is_required = 1
                }
                formData.append(
                    "inquiries[" + i + "][is_required]",
                    is_required
                );
                formData.append(
                    "inquiries[" + i + "][inquiry_id]",
                    element.inquiry_id
                );
            }
            return customerService.add_tab_inquery(formData, data.type, data.id).then(
                res => {
                    //console.log(res)
                    if (res.data.code && res.data.code == 500) {

                    } else {
                        if (data.type == 0) { //add tab
                            //     state.tab_inqueries = state.tab_inqueries.filter(v => v.id != data.id)
                            state.tab_inqueries = state.tab_inqueries.concat(res.data.tab)
                        } else {
                            state.tab_inqueries.forEach(v => {
                                if (v.id == data.id) {
                                    v.name = res.data.tab.name
                                    // console.log(res.data.tab.get_inquiries)
                                    v.get_inquiries = res.data.tab.get_inquiries
                                }
                            })
                        }
                    }
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        destroyTabInquiry({ state }, id) {
            return customerService.delete_tab_inquery(id).then(
                res => {
                    // console.log(response)
                    state.tab_inqueries = state.tab_inqueries.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },


        showAllSection({ state }, data) {
            state.print_selected = data
        },
        showCertenSection({ rootState, state }, data) {
            state.print_selected = data.data.print_array
            rootState.form.loader = false;
            rootState.form.dialog = false;
        },
        setPrintSelected({ rootState, state }, data) {
            // //console.log(data)
            state.print_selected = data.data.print_array
            rootState.form.loader = false;
            rootState.form.dialog = false;
            setTimeout(() => {
                var cards = document.getElementsByClassName("print_section");
                var div1 = ''
                var div2 = ''
                var count = cards.length;
                var y = 0, count1, length, status = false;
                var section = 2;
                var i;
                if (count % 2 == 0) {
                    length = count / 2
                    count1 = count
                } else {
                    count1 = count - 1;
                    length = count1 / 2;
                    status = true
                }
                for (i = 0; i < length; i++) {
                    const element = cards[i];
                    y++;
                    if (section == 1) {
                        div1 += `<div class="col-12">
                            <div class="section_card ">${element.innerHTML}</div>
                        </div>`;
                    } else {
                        div2 += `<div class="col-12">
                            <div class="section_card ">${element.innerHTML}</div>
                        </div>`;
                    }
                    if (y == length) {
                        if (length != count1) {
                            length = length * 2;
                            section = 1;
                        }
                    }
                }
                if (status) {
                    div1 += `<div class="col-12">
                    <div class="section_card ">${cards[count1].innerHTML}</div>
                </div>`;
                }
                var newWin = window.open("");
                // .section_card,.mb-4{
                //     margin-bottom: 16px !important;
                // }
                var data = `<html>
            <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet">
            <link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css" integrity="sha512-1sCRPdkRXhBV2PBLUdRb4tMg1w2YPf37qatUFeS7zlBy7jJI8Lf4VHwWfZZfpXtYSLy85pkm9GaYVYMfw5BC1A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        <link rel="stylesheet" type="text/css" href="${location.origin}/public/css/all.min.css">
        <link rel="stylesheet" type="text/css" href="${location.origin}/public/css/vuetify.min.css">
        <style>
            .grid{
                display:grid;
            }
            .v-application .text-lg{
                font-size:15px !important;
            }
            .answer{
                font-weight: bold;
                font-size: 14px;
            }
            .shadow-0 {
                box-shadow: none !important;
            }
            
            .v-sheet.v-card{
                border-radius : 0 0 4px 4px;
            }
            .mb-3{
                margin-bottom: 12px !important;
            }
            .text-xs {
                font-size: 0.75rem !important;
            }
            .text-sm {
                font-size: 0.875rem !important;
                line-height: 1.5;
            }
            .text-md {
                font-size: 1rem !important;
            }
            .attachment {
                background: #eee;
                border: 1px solid #ccc;
            }

            .attachment .character {
                text-align: center;
                background: #4c5d79;
                width: 100%;
                border: 1px solid #ccc;
                padding: 0 10px;
                color: #eee;
            }
            .v-sheet.v-card:not(.v-sheet--outlined),
            .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined){
                box-shadow:none;
            }
            .print-div .col-6 {
                flex: 0 0 50%;
                max-width: 50%;
            }
            .v-sheet.v-card.outlined{
                border:1px solid #cccccc8f;
            }
            .border-left-primary {
                border-bottom :1px solid #cccccc8f;
                border-right :1px solid #cccccc8f;
                border-left: 6px solid #009688;
                border-left-color: #009688 !important;
            }
            .theme--light.v-app-bar.v-toolbar.v-sheet{
                background-color : #eee
            }
            @media print {
                body * {
                    -webkit-print-color-adjust: exact;
                }
            }
        </style>
        </head>
            <body>
                <div class="v-application">
                    <div class="v-main__wrap">
                        <div class="print-div">
                            <div class=""container>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="row flex-column">
                                            ${div1}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row flex-column">
                                            ${div2}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </html>`;
                newWin.document.write(data);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 5000);

            }
                , 200);
        },

        saveAnswerRepeatableInquiries({ rootState }, data) {
            return customerService.answer_customer_repeatable_inquiries(data).then(
                (response) => {
                    // console.log(response);
                    return Promise.resolve(response);
                },
                (error) => {
                    //console.log(error);
                    return Promise.reject(error);
                }
            );
        },

        deleteInqueryRepeatable({ rootState, state }, data) {

            //console.log(data)
            Vue.swal({
                title: i18n.t("general.Are you sure?!"),
                text: i18n.t("You won't be able to revert this!"),
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: i18n.t("general.Yes, delete it!"),
                cancelButtonText: i18n.t("general.No, cancel!"),
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    const formData = new FormData();
                    formData.append("customer_id", data.id.customer_id);
                    formData.append("repeatable_id", data.id.id);

                    if (data.data.status) {
                        formData.append("global", "1");
                    } else {
                        formData.append("global", "0");
                    }
                    return customerService.delete_inquery_repeatable(formData).then(
                        res => {
                            //console.log(res)
                            var item = data.id.item
                            var year = state.repeatable_inquiries[item.year]
                            // Object.keys(year).forEach(function (month, indexmonth) {
                            //     console.log(month)
                            //     console.log(year)
                            //     if (month.length == 1) {
                            //         // year = Object.keys(year).filter((v) => v != month);
                            //         if (month == item.month)
                            //             year = delete month
                            //         // year = 
                            //         console.log(year)
                            //     } else {
                            //         month = month.filter((v) => v.id != data.id.id);
                            //     }
                            // })
                            // year = Object.keys(year).filter(function (v) {
                            //     console.log(year)
                            //     console.log(year[v])
                            //     if (v.length == 1) {
                            //         v != v
                            //     } else {
                            //         v = v.filter((s) => s.id != data.id.id)
                            //     }
                            // });
                            rootState.form.loader = false;
                            rootState.form.dialog = false;
                            Vue.swal.fire("Deleted!", res.data.message, "success").then((r) => {
                                if (r.isConfirmed) {
                                    window.location = location
                                }
                            });
                            return Promise.resolve(res);
                        },
                        error => {
                            rootState.form.loader = false;

                            Vue.swal.fire(
                                "Error",
                                i18n.t("general.There error please try again"),
                                "error"
                            );

                            return Promise.reject(error);
                        }
                    );
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Vue.swal.DismissReason.cancel
                ) {
                    Vue.swal.fire(
                        i18n.t("general.Cancelled"),
                        i18n.t("general.Cancelled Delete"),
                        "error"
                    );
                }
            });

        }
    },

};