
class CustomerService {
  get_cycles() {
    return axios.get('customer/customer-cycles')
  }
  apply_order(data) {
    return axios.post('orders/create', data)
  }
  get_order_customer(page, data) {
    return axios.post('customer/customer-orders?page=' + page, data)
  }
  get_order_inquiries(order_id) {
    return axios.get('orders/order-inquiries/' + order_id)
  }
  save_inquiry_answer(data, id) {
    return axios.post('orders/answer-inquiries/' + id, data, { headers: { "Content-type": "multipart/form-data" } })
  }
  get_ticket_customer(data) {
    return axios.post('ticket/user_tickets', data)
  }
  add_ticket(data) {
    return axios.post('ticket/store', data, { headers: { "Content-type": "multipart/form-data" } })
  }
  get_detail_ticket(id) {
    return axios.get('ticket/customer_ticket_show/' + id)
  }
  send_comment(data) {
    return axios.post('ticket/ticket_comment/store', data, { headers: { "Content-type": "multipart/form-data" } })
  }
  get_comment(id) {
    return axios.get('ticket/get_ticket_customer_comments/' + id)
  }

  assign_customer_inquiry(data) {
    // if (id == null)
    return axios.post('users/assign-inquiries', data)
    //   else
    //     return axios.post('users/assign-inquiries/' + id, data)
  }


  answer_customer_inquiries(data) {
    return axios.post('users/answer-inquiries', data, { headers: { "Content-type": "multipart/form-data" } })
  }

  answer_customer_repeatable_inquiries(data) {
    return axios.post('repeatable_inquiries/inquiryAnswers', data, { headers: { "Content-type": "multipart/form-data" } })
  }

  delete_inquery_repeatable(data) {
    return axios.post('repeatable_inquiries/delete-repeatable', data)
  }

  update_answer_customer_inquiries(id, data) {
    return axios.post('users/update-answer/' + id, data, { headers: { "Content-type": "multipart/form-data" } })
  }
  delete_customer_inquiries(id, data) {
    return axios.post('users/delete-inquiry/' + id, data)
  }

  customer_details(id) {
    return axios.get('users/customer-details/' + id)
  }

  profile() {
    return axios.get('users/profile')
  }

  get_tabs_inquery() {
    return axios.get('register_form_tab')
  }

  add_tab_inquery(data, type, id) {
    if (type == 1) { // mean is edit 
      return axios.post('register_form_tab/update/' + id, data)
    } else {
      return axios.post('register_form_tab/store', data)
    }
  }

  delete_tab_inquery(id) {
    return axios.delete('register_form_tab/destroy/' + id)
  }

  get_form_tabs_inquery() {
    return axios.get('register_form_answer')
  }

  add_form_register_inquery(data) {
    return axios.post('register_form_answer/store', data, { headers: { "Content-type": "multipart/form-data" } })
  }

  add_form_register_inquery_from_admin(id, data) {
    return axios.post('register_form_answer/store-from-admin/' + id, data, { headers: { "Content-type": "multipart/form-data" } })
  }

  get_chart_inquiries(id) {
    return axios.get('repeatable_inquiries/index/' + id)
  }
  get_chart_data(data) {
    return axios.post('repeatable_inquiries/RepeatableInquiryChart', data);
  }
}
export default new CustomerService();