import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import { form } from './form.module';
import { order } from './order.module';
import { ticket } from './ticket.module';
import { inquiry } from './inquiry.module';
import { stage } from './stage.module';
import { cms } from './cms.module';
import { customer } from './customer.module';
Vue.use(Vuex);
const myPlugin = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // console.log(mutation)
        switch (mutation.type) {
            case 'form/SAVE_FORM_DATA':
                console.log('user details updated');
                if (mutation.payload.id) {
                    store.dispatch(mutation.payload.name, { data: state.form.form_data, id: mutation.payload.id })
                } else {
                    store.dispatch(mutation.payload.name, { data: state.form.form_data })
                }
                break;
            default:
                break;
        }
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    })
};
export default new Vuex.Store({
    state: () => ({
        unread_notify: '',
        notifications: [],
        read_more: true
    }),
    getters: {
        read_all_notification(state) {
            return state.notifications.map((v) => { return v.is_seen = 1 })
        }
    },
    mutations: {
        SET_COUNT_UNREAD_MESSAGE(state, data) {
            state.unread_notify = data
        },
        SET_NOTIFICATIONS(state, data) {
            state.notifications = data
        },
        SET_READ_MORE(state, data) {
            state.read_more = data
        },
        ALL_NOTIFICATION_READ(state) {
            state.unread_notify = ''
            return state.notifications.map((v) => { return v.is_seen = 1 })
        },
    },
    actions: {
        getNotifocations({ commit }) {
            Vue.$http.post("/notification/user_notification", { first: true })
                .then((response) => {
                    commit("SET_NOTIFICATIONS", response.data.notifications.data)
                    if (response.data.notifications.total == response.data.notifications.data.length) {
                        commit("SET_READ_MORE", false)
                    }
                    commit(
                        "SET_COUNT_UNREAD_MESSAGE", response.data.unread_notify);
                });
        },
        seeMoreNotifications({ state, commit }, counter) {
            Vue.$http
                .post("/notification/user_notification?page=" + counter)
                .then((response) => {
                    var res = response.data.notifications;
                    let count = res.data.length;
                    for (let i = 0; i < count; i++) {
                        const element = res.data[i];
                        console.log(element);
                        state.notifications.push(element);
                    }
                    if (res.total == state.notifications.length) {
                        commit("SET_READ_MORE", false);
                    }
                });
        },
        increaseUnreadMessage({ state }) {
            if (state.unread_notify > 0) {
                state.unread_notify--
            }
        },
        allNotificationsRead({ commit }) {
            Vue.$http
                .get("/notification/make_all_notification_seen")
                .then(() => {
                    commit("ALL_NOTIFICATION_READ")
                });
        },

    },
    plugins: [myPlugin],
    modules: {
        auth,
        form,
        order,
        ticket,
        inquiry,
        stage,
        cms,
        customer
    }
});