<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <div class="login">
        <div class="row mb-0">
          <div class="col-md-5 pb-0 d-none d-md-block">
            <img class="image" src="@/assets/img/alborj/login.png" alt="" />
          </div>
          <div class="col-md-7 pb-0">
            <div class="form">
              <div class="container">
                <div class="row">
                  <div class="col-md-2 pt-0"></div>
                  <div class="col-md-8 pt-0 section-form">
                    <fade-transition
                      :duration="200"
                      origin="center top"
                      mode="out-in"
                    >
                      <!-- your content here -->
                      <router-view></router-view>
                    </fade-transition>
                  </div>
                  <div class="col-md-2">
                    <v-btn
                      icon
                      :ripple="false"
                      class="btn-lang"
                      small
                      @click="change_lang()"
                    >
                      {{ lang }}
                    </v-btn>
                  </div>
                  <!--  <v-menu
                      transition="slide-y-transition"
                      offset-y
                      offset-x
                      min-width="300"
                      max-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :ripple="false"
                          class="btn-lang"
                          v-bind="attrs"
                          v-on="on"
                          small
                        >
                          {{ lang }}
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          v-for="(item, i) in language"
                          :key="i"
                          class="px-4 py-2 list-item-hover-active"
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="
                                text-body-2
                                ls-0
                                text-typo
                                font-weight-600
                                mb-0
                              "
                            >
                              <v-row>
                                <v-col @click="change_lang(item.abbr)">
                                  <h6
                                    class="
                                      text-sm
                                      font-weight-normal
                                      mb-1
                                      text-typo
                                    "
                                    v-html="item.title"
                                  >
                                    {{ item.title }}
                                  </h6>
                                </v-col>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      lang: "",
      abbr: "",
      language: [
        { title: '<span class="font-weight-bold">EN</h6>', abbr: "en" },
        { title: '<span class="font-weight-bold">AR</h6>', abbr: "ar" },
      ],
    };
  },
  methods: {
    change_lang() {
      if (this.abbr == "ar") {
        this.lang = this.$i18n.t("general.English") + " (EN)";
        this.abbr = "en";
        localStorage.setItem("lang", "ar");
      } else {
        this.lang = this.$i18n.t("general.Arabic") + " (AR)";
        this.abbr = "ar";
        localStorage.setItem("lang", "en");
      }
      // alert(this.abbr);
      window.location.reload();
    },
  },
  mounted() {
    if (localStorage.getItem("lang") == "ar") {
      this.abbr = "en";
      this.lang = this.$i18n.t("general.English") + " (EN)";
    } else if (localStorage.getItem("lang") == "en") {
      this.abbr = "ar";
      this.lang = this.$i18n.t("general.Arabic") + " (AR)";
    }
  },
  created() {
    this.$vuetify.rtl = false;
  },
  beforeDestroy() {
    this.$vuetify.rtl = false;
  },
};
</script>
<style lang="css">
html:lang(ar) .section-form {
  direction: rtl;
  /* text-align: right; */
}
</style>
