<template>
  <v-app>
    <drawer
      :drawer="drawer"
      :sidebarColor="sidebarColor"
      :sidebarTheme="sidebarTheme"
    ></drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <app-bar
        v-if="$route.name != 'Profiles'"
        background="bg-transparent"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
        :navbarFixed="navbarFixed"
        @toggleSettingsDrawer="toggleSettingsDrawer"
      ></app-bar>
      <app-bar
        v-else-if="$route.name == 'Profiles'"
        background="bg-default"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <app-bar
        v-else
        background="primary"
        linkColor="rgba(0,0,0,.6)"
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
      <!-- <v-btn
        :ripple="false"
        icon
        rounded
        color="#fff"
        width="52px"
        height="52px"
        class="
          fixed-plugin-button
          position-fixed
          btn-light
          bg-white
          text-dark
          z-index-9999
        "
        @click="showSettingsDrawer = true"
      >
        <v-icon size="20">fa fa-cog py-2</v-icon>
      </v-btn>-->

      <settings-drawer
        :showSettingsDrawer="showSettingsDrawer"
        @toggleSettingsDrawer="toggleSettingsDrawer"
        @updateSidebarColor="updateSidebarColor"
        @updateSidebarTheme="updateSidebarTheme"
        @toggleNavbarPosition="toggleNavbarPosition"
      ></settings-drawer>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";
import SettingsDrawer from "@/components/Widgets/SettingsDrawer.vue";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBar,
    SettingsDrawer,
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "success",
      sidebarTheme: "transparent",
      navbarFixed: false,
      timeoutInMS: 120000, // 3 minutes -> 3 * 60 * 1000
      timeoutId: null,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    setupTimers() {
      // console.log("step timer");
      document.addEventListener("keypress", this.resetTimer(), false);
      document.addEventListener("mousemove", this.resetTimer(), false);
      document.addEventListener("mousedown", this.resetTimer(), false);
      document.addEventListener("touchmove", this.resetTimer(), false);

      this.startTimer();
    },
    resetTimer() {
      clearTimeout(this.timeoutId);
      this.startTimer();
    },
    startTimer() {
      // setTimeout returns an ID (can be used to start or clear a timer)
      timeoutId = setTimeout(this.handleInactive(), this.timeoutInMS);
    },
    handleInactive() {
      // console.log("test");
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$router.push(localStorage.getItem("login_page"));
        })
        .catch((err) => console.log(err));
      // Here you want to logout a user and/or ping your token
    },
  },
  mounted() {
    // console.log("layout");
    this.initScrollbar();
    if (localStorage.getItem("lang") == "ar") {
      this.$vuetify.rtl = true;
    } else if (localStorage.getItem("lang") == "en") {
      this.$vuetify.rtl = false;
    }
    // this.setupTimers();
    this.$setupTimers;
  },
};
</script>
<style lang="scss"></style>
<style>
@media print {
  .print {
    /* border: 1px solid black; */
    border: none;
    border-collapse: collapse;
  }
  .print td {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }

  .print td:first-child {
    border-left: none;
  }

  .print td:last-child {
    border-right: none;
  }

  tr > td:last-of-type {
    display: none;
  }
  tr > th:last-of-type {
    display: none;
  }
}
</style>