import CmsService from '../services/cms.service'
import i18n from '@/i18n.js'
export const cms = {
    namespaced: true,
    state: () => ({
        protfolio: [],
        teams: [],
        sliders: [],
        news: [],
        comments: [],
        statistics: [],
        approach: [],
        approach_paragraph: [],
        philosophys: [],
        words: [],
        quotes: []
    }),
    mutations: {
        CHANGE_STATUS_NEWS(state, data) {
            state.comments.forEach(v => {
                if (v.id == data.id) {
                    v.status = data.status
                }
            });
        }
    },
    actions: {

        // slider
        getSliders({ state, rootState }) {
            return CmsService.get_sliders().then(
                res => {
                    // console.log(res)
                    state.sliders = res.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addSlider({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            formData.append("type", 'slider');
            return CmsService.add_slider(formData).then(
                res => {
                    // console.log(res)
                    state.sliders = state.sliders.concat(res.data.slider)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editSlider({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            // console.log(data)
            return CmsService.edit_slider(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.sliders = state.sliders.filter(v => v.id != data.id)
                    state.sliders = state.sliders.concat(res.data.slider)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroySlider({ state }, id) {
            return CmsService.destroy_slider(id).then(
                res => {
                    // console.log(response)
                    state.sliders = state.sliders.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },


        // team
        getTeams({ state, rootState }) {
            return CmsService.get_teams().then(
                res => {
                    // console.log(res)
                    state.teams = res.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addTeam({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.photo != null) {
                formData.append("photo", data.data.photo);
            }
            formData.append("name", data.data.name);
            formData.append("position", data.data.position)
            formData.append("facebook", data.data.facebook)
            formData.append("instagram", data.data.instagram)
            formData.append("linkedin", data.data.linkedin)
            formData.append("tweeter", data.data.tweeter)
            return CmsService.add_team(formData).then(
                res => {
                    // console.log(res)
                    state.teams = state.teams.concat(res.data.team_member)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editTeam({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.photo != null) {
                formData.append("photo", data.data.photo);
            }
            formData.append("name", data.data.name);
            formData.append("position", data.data.position)
            formData.append("facebook", data.data.facebook)
            formData.append("instagram", data.data.instagram)
            formData.append("linkedin", data.data.linkedin)
            formData.append("tweeter", data.data.tweeter)
            return CmsService.edit_team(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.teams = state.teams.filter(v => v.id != data.id)
                    state.teams = state.teams.concat(res.data.team_member)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyTeam({ state }, id) {
            return CmsService.destroy_team(id).then(
                res => {
                    // console.log(response)
                    state.teams = state.teams.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },

        // protfolio
        getProtfolio({ state, rootState }) {
            return CmsService.get_protfolio().then(
                res => {
                    // console.log(res)
                    state.protfolio = res.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addProtfolio({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            formData.append("body", data.data.body);
            return CmsService.add_protfolio(formData).then(
                res => {
                    // console.log(res)
                    state.protfolio = state.protfolio.concat(res.data.protfolio)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editProtfolio({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            formData.append("body", data.data.body);
            return CmsService.edit_protfolio(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.protfolio = state.protfolio.filter(v => v.id != data.id)
                    state.protfolio = state.protfolio.concat(res.data.protfolio)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyProtfolio({ state }, id) {
            return CmsService.destroy_protfolio(id).then(
                res => {
                    // console.log(response)
                    state.protfolio = state.protfolio.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },

        // news
        getNews({ state, rootState }, data) {
            return CmsService.get_news(data.page, data.data).then(
                res => {
                    // console.log(res)
                    state.news = res.data.news.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addNews({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            formData.append("body", data.data.body);
            formData.append("subject", data.data.subject);
            formData.append("type", 'news');
            return CmsService.add_news(formData).then(
                res => {
                    // console.log(res)
                    state.news = state.news.concat(res.data.news)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editNews({ rootState, state }, data) {
            // console.log(data)
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            formData.append("body", data.data.body);
            formData.append("subject", data.data.subject);
            return CmsService.edit_news(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.news = state.news.filter(v => v.id != data.id)
                    state.news = state.news.concat(res.data.news)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyNews({ state }, id) {
            return CmsService.destroy_news(id).then(
                res => {
                    // console.log(response)
                    state.news = state.news.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        approveComment({ commit, state }, id) {
            return CmsService.approve_comment(id).then(
                res => {
                    // console.log(res)
                    state.comments = state.comments.filter(v => v.id != id)
                    state.comments.unshift(res.data.status)
                    // commit('CHANGE_STATUS_NEWS', res.data.status)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        getComments({ state, rootState }, id) {
            return CmsService.get_news_comments(id).then(
                res => {
                    // console.log(res)
                    state.comments = res.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        rejectComment({ commit, state }, id) {
            return CmsService.reject_comment(id).then(
                res => {
                    // console.log(response)
                    state.comments = state.comments.filter(v => v.id != id)
                    state.comments.unshift(res.data.status)
                    // commit('CHANGE_STATUS_NEWS', res.data.status)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },


        // Philosophys
        getPhilosophys({ state, rootState }, data) {
            return CmsService.get_philosophys(data.page, data.data).then(
                res => {
                    // console.log(res)
                    state.philosophys = res.data.philosophys.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addPhilosophy({ rootState, state }, data) {
            const formData = new FormData();
            formData.append("body", data.data.body);
            formData.append("title", data.data.title);
            return CmsService.add_philosophy(formData).then(
                res => {
                    // console.log(res)
                    state.philosophys = state.philosophys.concat(res.data.philosophy)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editPhilosophy({ rootState, state }, data) {
            const formData = new FormData();
            formData.append("body", data.data.body);
            formData.append("title", data.data.title);
            // console.log(data)
            return CmsService.edit_philosophy(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.philosophys = state.philosophys.filter(v => v.id != data.id)
                    state.philosophys = state.philosophys.concat(res.data.philosophy)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyPhilosophy({ state }, id) {
            return CmsService.destroy_philosophy(id).then(
                res => {
                    // console.log(response)
                    state.philosophys = state.philosophys.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    console.log(error)
                    return Promise.reject(error);
                }
            );
        },

        // Approach
        getApproach({ state, rootState }) {
            return CmsService.get_approach().then(
                res => {
                    console.log(res)
                    state.approach = res.data.approach
                    state.approach_paragraph = res.data.paragraph
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addApproach({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            formData.append("body", data.data.body);
            formData.append("type", 'approach');
            return CmsService.add_approach(formData).then(
                res => {
                    console.log(res)
                    state.approach = state.approach.concat(res.data.approach)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editApproach({ rootState, state }, data) {
            const formData = new FormData();
            if (data.data.image != null) {
                formData.append("image", data.data.image);
            }
            formData.append("title", data.data.title);
            formData.append("body", data.data.body);

            console.log(data)
            return CmsService.edit_approach(data.id, formData).then(
                res => {
                    console.log(res)
                    state.approach = state.approach.filter(v => v.id != data.id)
                    state.approach = state.approach.concat(res.data.approach)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyApproach({ state }, id) {
            return CmsService.destroy_approach(id).then(
                res => {
                    // console.log(response)
                    state.approach = state.approach.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        editParagraphApproach({ rootState, state }, data) {

            return CmsService.edit_paragraph_approach(data.id, data.data).then(
                res => {
                    console.log(res)
                    state.approach_paragraph = state.approach_paragraph.filter(v => v.id != data.id)
                    state.approach_paragraph = state.approach_paragraph.concat(res.data.paragraph)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },

        getParagraphApproach({ state, rootState }) {
            return CmsService.get_paragraph_approach().then(
                res => {
                    // console.log(res)
                    state.approach_paragraph = res.data.paragraph
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },


        // word
        getwords({ state, rootState }) {
            return CmsService.get_words().then(
                res => {
                    // console.log(res)
                    state.words = res.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addWord({ rootState, state }, data) {
            const formData = new FormData();
            formData.append("word", data.data.word);
            return CmsService.add_word(formData).then(
                res => {
                    // console.log(res)
                    state.words = state.words.concat(res.data.word)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editWord({ rootState, state }, data) {
            const formData = new FormData();
            formData.append("word", data.data.word);
            // console.log(data)
            return CmsService.edit_word(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.words = state.words.filter(v => v.id != data.id)
                    state.words = state.words.concat(res.data.word)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyWord({ state }, id) {
            return CmsService.destroy_word(id).then(
                res => {
                    // console.log(response)
                    state.words = state.words.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },

        // quote
        getquotes({ state, rootState }) {
            return CmsService.get_quotes().then(
                res => {
                    // console.log(res)
                    state.quotes = res.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },

        editQuote({ rootState, state }, data) {
            const formData = new FormData();
            formData.append("quote", data.data.quote);
            formData.append("owner", data.data.owner);
            // console.log(data)
            return CmsService.edit_quote(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.quotes = state.quotes.filter(v => v.id != data.id)
                    state.quotes = state.quotes.concat(res.data.quote)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },

        // statistics
        getstatistics({ state, rootState }) {
            return CmsService.get_statistics().then(
                res => {
                    // console.log(res)
                    state.statistics = res.data
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },

        editStatistic({ rootState, state }, data) {
            const formData = new FormData();
            formData.append("value", data.data.value);
            // console.log(data)
            return CmsService.edit_statistic(data.id, formData).then(
                res => {
                    // console.log(res)
                    state.statistics = state.statistics.filter(v => v.id != data.id)
                    state.statistics = state.statistics.concat(res.data.statistics)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        // console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
    },

};