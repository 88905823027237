class TicketService {

  // admin
  destroy_admin_from_group(data) {
    return axios.post('ticket/ticket_group_admins/remove_admin_from_group', data)
  }


  // group admin
  get_group_admin() {
    return axios.get('ticket/ticket_group_admins/group_list')
  }
  add_group_admin(data) {
    return axios.post('ticket/ticket_group_admins/store', data)
  }
  edit_group_admin(id, data) {
    return axios.post('ticket/ticket_group_admins/update/' + id, data)
  }
  destroy_group_admin(id) {
    return axios.delete('ticket/ticket_group_admins/destroy/' + id)
  }

  // ticket subject
  get_ticket_subject(page, data) {
    return axios.post('ticket/ticket_subject?page=' + page, data)
  }
  add_ticket_subject(data) {
    return axios.post('ticket/ticket_subject/store', data)
  }
  edit_ticket_subject(id, data) {
    return axios.post('ticket/ticket_subject/update/' + id, data)
  }
  destroy_ticket_subject(id) {
    return axios.delete('ticket/ticket_subject/destroy/' + id)
  }

  // ticket
  filter_ticket() {
    return axios.get('ticket/filters')
  }
  export(data) {
    return axios.post('ticket/export', data)
  }
  get_ticket_admin(page, data) {
    return axios.post('ticket/admin_tickets?page=' + page, data)
  }
  get_detail_ticket(id) {
    return axios.get('ticket/show/' + id)
  }
  get_comment(id) {
    return axios.get('ticket/get_ticket_admin_comments/' + id)
  }
  close_ticket(id) {
    return axios.post('ticket/close_ticket/' + id)
  }
  forword_ticket(data) {
    return axios.post('ticket/forward_ticket', data)
  }
  importance_level_ticket(data) {
    return axios.post('ticket/change_importance', data)
  }
  send_comment(data) {
    return axios.post('ticket/ticket_comment/store', data, { headers: { "Content-type": "multipart/form-data" } })
  }

}
export default new TicketService();