import OrderService from '../services/order.service';
import i18n from '@/i18n.js'
export const order = {
    namespaced: true,
    state: () => ({
        orders: [],
        orderDetails: {},
        items_select: {},
        data: {},
    }),
    getters: {
        items_select_cycle(state) {
            if (state.items_select.cycles.length > 0) {
                return state.items_select.cycles.filter(v => v.id != state.data.id)
            }
            return;
        }
    },
    actions: {
        changeCycle({ commit, rootState }, data) {
            // console.log('data change_cycle')
            return OrderService.change_cycle(data.id, data.data).then(
                res => {
                    // //console.log(res)
                    if (res.data.order) {
                        rootState.form.loader = false;
                        rootState.form.dialog = false;
                        rootState.form.notify = {
                            msg: res.data.message,
                            type: "Success",
                        };
                        commit('REMOVE_UPDATE_ORDER', { order: res.data.order, id: data.id, type: 'cycle' });
                    }
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            );
        },
        addInqueries({ rootState }, data) {
            // console.log('data add')
            // console.log(data)
            return OrderService.assign_inquiries(data.id, data).then(
                res => {
                    //console.log(res)
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            );
        },
        changeStatus({ commit, rootState }, id) {
            return OrderService.change_status(id).then(
                res => {
                    // //console.log(res)
                    if (res.data.status) {
                        commit('REMOVE_UPDATE_ORDER', { order: res.data.status, id: id, type: 'status' });
                        // rootState.form.notify = {
                        //     msg: res.data.message,
                        //     type: "Success",
                        // };
                    }

                    return Promise.resolve(res);
                },
                error => {
                    // rootState.form.notify = {
                    //     msg: i18n.t("general.there is problem"),
                    //     type: "Danger",
                    // };
                    return Promise.reject(error);
                }
            );
        },
        changeStage({ commit, rootState }, id) {
            return OrderService.change_stage(id).then(
                res => {
                    // //console.log(res)
                    if (res.data.stage) {
                        commit('REMOVE_UPDATE_ORDER', { order: res.data.order.stages.name, id: id, type: 'stage' });
                        // rootState.form.notify = {
                        //     msg: data.data.message,
                        //     type: "Success",
                        // }
                    }
                    return Promise.resolve(res);
                },
                error => {
                    if (error.response.status == 405) {
                        // rootState.form.notify = {
                        //     msg: error.response.data.message,
                        //     type: "Warning",
                        // };
                    } else {
                        // rootState.form.notify = {
                        //     msg: i18n.t("general.there is problem"),
                        //     type: "Danger",
                        // };
                    }
                    return Promise.reject(error);
                }
            );
        },
        canceleOrder({ commit, rootState }, id) {
            return OrderService.reject_order(id).then(
                res => {
                    //console.log(res)
                    if (res.data.status) {
                        commit('REMOVE_UPDATE_ORDER', { order: res.data.status, id: id, type: 'cancel' });
                        // rootState.form.notify = {
                        //     msg: res.data.message,
                        //     type: "Success",
                        // };
                    }
                    return Promise.resolve(res);
                },
                error => {
                    // rootState.form.notify = {
                    //     msg: i18n.t("general.there is problem"),
                    //     type: "Danger",
                    // };
                    return Promise.reject(error);
                }
            );
        },
        getOrdersAction({ commit }, data_send) {
            return OrderService.get_orders(data_send.page, data_send.data).then(
                data => {
                    commit('UPDATE_ORDER', data.data.orders.data);
                    if (data_send.first) {
                        // alert('here')
                        commit('SET_ITEM_SELECT_BOX', { cycles: data.data.cycles, inqueries: data.data.inqueries, inquiry_group: data.data.inquiry_group });

                    }
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        getOrderAction({ commit, state }, id) {
            // alert(id)
            // if(state.orderDetails[id]) return Promise.resolve({'data': state.orderDetails[id]});
            return OrderService.get_order(id).then(
                res => {
                    // //console.log(res)
                    // commit('UPDATE_ORDER_DETAIL', { ...state.orderDetails, [id]: res.data });
                    commit('UPDATE_ORDER_DETAIL', res.data);
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                })
        },
    },
    mutations: {
        SET_ITEM_SELECT_BOX(state, data) {
            state.items_select = data
        },
        SET_DATA(state, data) {
            // state.disabled_item.push(data)
            state.data = data
        },
        UPDATE_ORDER(state, orders) {
            state.orders = orders
        },
        UPDATE_ORDER_DETAIL(state, orderDetails) {
            // state.orderDetails = JSON.parse(JSON.stringify(orderDetails));
            state.orderDetails = orderDetails;

        },
        REMOVE_UPDATE_ORDER(state, data) {

            state.orders.forEach(v => {
                if (v.id == data.id) {
                    if (data.type == 'status') {
                        v.statuses.status = data.order.status
                        v.statuses.id = data.order.id
                        v.status_id = data.order.id
                    } else if (data.type == 'stage') {
                        v.stages.name_lang = data.order.name_lang
                    } else if (data.type == 'cycle') {
                        // console.log('cycle')
                        // console.log(data)
                        v.cycles.name_lang = data.order.cycles.name_lang
                        v.stages.name_lang = data.order.stages.name_lang
                    }
                    else if (data.type == 'cancel') {
                        v.statuses.status = data.order.statuses.status
                        v.statuses.id = data.order.statuses.id
                        v.status_id = data.order.statuses.id
                    }

                }
            });
        },

    },
};