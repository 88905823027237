export const form = {
    namespaced: true,
    state: () => ({
        data: {},
        file_image: null,
        form_data: {},
        style_form: [],
        notify: {
            color: null,
            visible: false,
            msg: '',
            note: ''
        },
        loader: false,
        dialog: false,
        info_call: null,
        action: '',
        collection: ''
    }),
    getters: {
        notifyStyle(state) {
            if (!state.notify.type) {
                return state.notify;
            } else {
                switch (state.notify.type) {
                    case "Info":
                        return state.notify = {
                            color: "#17c1e8",
                            visible: true,
                            msg: state.notify.msg,
                            note: 'Info'
                        };
                        break;
                    case "Success":
                        return state.notify = {
                            color: "#82d616",
                            visible: true,
                            msg: state.notify.msg,
                            note: 'Success'
                        };
                        break;
                    case "Warning":
                        return state.notify = {
                            color: "#fbcf33",
                            visible: true,
                            msg: state.notify.msg,
                            note: 'Warning'
                        };
                        break;
                    case "Danger":
                        return state.notify = {
                            color: "#ea0606",
                            visible: true,
                            msg: state.notify.msg,
                            note: 'Error'
                        };
                        break;
                }
            }
        }
    },
    actions: {
        setNotify({ commit }, data) {
            commit("SET_NOTIFY", data)
        },
        setCOLLECTION({ commit }, data) {
            commit("SET_COLLECTION", data)
        },
        setFile({ commit }, data) {
            // console.log('set_file')
            commit("SET_FILE", data)
        },
        setFormData({ commit }, data) {
            commit("SET_FORM_DATA", data)
        },
        setData({ commit }, data) {
            commit("SET_DATA", data)
        },
        setItemData({ commit }, data) {
            commit("SET_ITEM_DATA", data)
        },
        saveFormData({ commit }, data) {
            commit("SAVE_FORM_DATA", data)
        },
        resetNotify({ commit }) {
            // alert('reset')
            commit("RESET_NOTIFY")
        }

    },
    mutations: {
        // RESET_DATA_FORM(state){
        //     for (let i = 0; i < state.style_form.length; i++) {
        //         const element = state.style_form[i];
        //         state.form_data[element.value_text] = ''
        //     }
        // },
        SET_DATA(state, data) {
            // console.log(data)
            state.style_form = data
        },
        SET_FILE(state, data) {
            // console.log('set_file mutation')
            state.file_image = data
        },
        SET_ACTION(state, data) {
            state.action = data;
        },
        RESET_ERROR(state) {
            for (let i = 0; i < state.style_form.length; i++) {
                const element = state.style_form[i];
                element.error = null
            }
        },

        SET_NOTIFY(state, data) {
            state.notify = data
        },
        RESET_NOTIFY(state) {
            state.notify = {
                color: null,
                visible: false,
                msg: '',
                note: ''
            }
        },
        SET_COLLECTION(state, data) {
            state.collection = data
        },
        SET_FORM_DATA(state, data) {
            state.form_data = data
        },
        SET_ITEM_DATA(state, data) {
            state.style_form[data.index].items = data.data
        },
        SAVE_FORM_DATA(state, data) {
        },
        INFO_CALL(state, data) {
            state.info_call = null
            state.info_call = data
        },
        SET_LOADER(state, data) {
            state.loader = data
        },
        SET_DIALOG(state, data) {
            state.dialog = data
        }


    },
};