class orderService {
  get_orders(page, data) {
    return axios.post('orders?page=' + page, data)
  }
  get_order(id) {
    return axios.get('orders/show/' + id)
  }
  edit_order(id, data) {
    return axios.post('orders/update/' + id, data)
  }
  change_status(id) {
    return axios.post('orders/change-order-status/' + id)
  }
  change_stage(id) {
    return axios.put('orders/change-order-stage/' + id)
  }
  change_cycle(id, data) {
    return axios.post('orders/change-order-cycle/' + id, data)
  }
  reject_order(id) {
    return axios.put('orders/reject-order/' + id)
  }
  assign_inquiries(id, data) {
    return axios.post('orders/assign-inquiries/' + id, data)
  }

}
export default new orderService();