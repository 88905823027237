
class AuthService {
    login(user) {
        return axios
            .post('auth/login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                // var data = response.data.data.original
                // if (data.access_token) {
                //     localStorage.setItem('permissions', JSON.stringify(data.permissions));
                //     localStorage.setItem('user', JSON.stringify(data.user));
                //     localStorage.setItem('accessToken', JSON.stringify(data.access_token));
                //     localStorage.setItem('is_admin', JSON.stringify(response.data.is_admin));
                // }
                return response.data;
            });
    }
    login_google() {
        return axios
            .get('auth/google')
            .then(response => {
                return response;
            });
    }
    login_google_callback(code) {
        return axios
            .get('auth/google/callback', { params: { code: code } })
            .then(response => {
                console.log(response)
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    localStorage.setItem('accessToken', JSON.stringify(response.data.token));
                    localStorage.setItem('is_admin', JSON.stringify(response.data.is_admin));
                }
                return response.data;
            });
    }
    login_facebook() {
        return axios
            .get('auth/facebook')
            .then(response => {
                return response;
            });
    }
    login_facebook_callback(code) {
        return axios
            .get('auth/facebook/callback', { params: { code: code } })
            .then(response => {
                console.log(response)
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    localStorage.setItem('accessToken', JSON.stringify(response.data.token));
                    localStorage.setItem('is_admin', JSON.stringify(response.data.is_admin));
                }
                return response.data;
            });
    }
    logout() {
        if (localStorage.getItem('is_admin') == 1) {
            localStorage.setItem('login_page', '/admin/login');
        } else {
            localStorage.setItem('login_page', '/login');
        }
        return axios.post('auth/logout')
            .then(resp => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                localStorage.removeItem('cycle_id');
                localStorage.removeItem('is_admin');
                localStorage.removeItem('permissions')
                return resp
            })
            .catch(err => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                localStorage.removeItem('cycle_id');
                localStorage.removeItem('is_admin');
                localStorage.removeItem('permissions')
                return err
            })
    }
    register(user) {
        return axios.post('auth/register', user);
    }
    reset_password(email) {
        return axios.post('auth/password-reset-request', email)
    }
    new_password(data) {
        return axios.post('auth/password-reset', data)
    }
}
export default new AuthService();