<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      lang: "",
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="css">
html:lang(ar) .section-form {
  direction: rtl;
  /* text-align: right; */
}
.btn-lang {
  font-size: 18px !important;
  color: #a1a1a1 !important;
}
</style>
