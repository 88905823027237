import Vue from "vue";
import VueRouter from "vue-router";
import store from './../store'
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import BlankLayout from "../views/Layout/BlankLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";

// inquiries
const InquiryPage = () => import("@/views/Inquiries/Show.vue");
const InquiryReport = () => import("@/views/Inquiries/Report.vue");

// inquiry groups
const GroupPage = () => import("@/views/Groups/Show.vue");


// cycle
const CyclePage = () => import("@/views/Cycles/Show.vue");
const NewCycle = () => import("@/views/Cycles/Create.vue");
const EditCycle = () => import("@/views/Cycles/Edit.vue");
const DetailCycle = () => import("@/views/Cycles/Details.vue");


// order
const OrderPage = () => import("@/views/Orders/Show.vue");
// const EditOrder = () => import ("@/views/Orders/Edit.vue");
const DetailOrder = () => import("@/views/Orders/Details.vue");


// stage
const StagePage = () => import("@/views/Stages/Show.vue");


// ticket subject
const TicketSubjectPage = () => import("@/views/TiketsSubject/Show.vue");

// admin group
const AdminGroupPage = () => import("@/views/AdminGroups/Show.vue");

// ticket
// const NewTicket = () => import ("@/views/Ticket/Create.vue");
const DetailsTicket = () => import("@/views/Ticket/Details.vue");
const TicketPage = () => import("@/views/Ticket/Show.vue");

// customer pages
const Profile = () => import("@/views/Customer/Profile/Index.vue");
const Forms = () => import("@/views/Customer/Profile/Forms.vue");
const Cycles = () => import("@/views/Customer/Cycle.vue");
const Orders = () => import("@/views/Customer/Order.vue");
const Inqueries = () => import("@/views/Customer/Inqueries.vue");
const Ticket = () => import("@/views/Customer/Ticket.vue");
const DetailTicket = () => import("@/views/Customer/DetailsTicket.vue");

const ProfileCustomer = () => import("@/views/Users/Customer/profile.vue");
const CreateCustomer = () => import("@/views/Users/Customer/create.vue");

// cms

const SlidePage = () => import("@/views/Cms/Slider.vue");
const TeamPage = () => import("@/views/Cms/Team.vue");
const NewsPage = () => import("@/views/Cms/News.vue");
const NewsCommentsPage = () => import("@/views/Cms/NewsComments.vue");
const BadWordsPage = () => import("@/views/Cms/BadWords.vue");
const ProtfolioPage = () => import("@/views/Cms/Protfolio.vue");
const QuotePage = () => import("@/views/Cms/Quote.vue");
const StatisticsPage = () => import("@/views/Cms/Statistics.vue");
const PhilosophiePage = () => import("@/views/Cms/Philosophies.vue");
const ApproachIndex = () => import("@/views/Cms/Approach/Index.vue");
const ApproachHome = () => import("@/views/Cms/Approach/Home.vue");


// Dashboard pages
const Dashboard = () =>
    import("../views/Dashboard/Dashboard.vue");
const Automotive = () =>
    import("../views/Dashboard/Automotive.vue");
const SmartHome = () =>
    import("../views/Dashboard/SmartHome.vue");
const VrDefault = () =>
    import("../views/Dashboard/VrDefault.vue");
const VrInfo = () =>
    import("../views/Dashboard/VrInfo.vue");
const Crm = () =>
    import("../views/Dashboard/Crm.vue");
const GettingStarted = () =>
    import("../views/Dashboard/GettingStarted.vue");

// Pages
const Pricing = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Profile/ProfileOverview.vue"
    );
const Teams = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Profile/Teams.vue");
const Projects = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const SignUpBasic = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Basic.vue"
    );
const SignUpCover = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Cover.vue"
    );

const SignUpIllustration = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Pages/Authentication/SignUp/Illustration.vue"
    );

// Applications
const Kanban = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
    import( /* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/NewProduct.vue"
    );
const EditProduct = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/EditProduct.vue"
    );
const ProductPage = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Products/ProductPage.vue"
    );
const OrderList = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Orders/OrderList.vue"
    );
const OrderDetails = () =>
    import(
        /* webpackChunkName: "pages" */
        "@/views/Ecommerce/Orders/OrderDetails.vue"
    );



/************** Start Store Routes ***********************/
/****************************************************** */
/****************************************************** */
/****************************************************** */
const LogIn = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/Login.vue"
    );

const Email = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/Email.vue"
    );

const ResetPassword = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/ResetPassword.vue"
    );

const LogInAdmin = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/LogInAdmin.vue"
    );

const Google = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/Google.vue"
    );

const Signup = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Auth/Register.vue"
    );
/****************************************************** */
/****************************************************** */
/****************************************************** */
/************** End Store Routes ***********************/

Vue.use(VueRouter);

let InquiriesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Inquiry",
    children: [
        {
            path: "/inquiry/show",
            name: "InquiryPage",
            component: InquiryPage,
            meta: {
                groupName: "Inquiry",
                permissions: 'inquiry-index',
                requiresAuth: true
            },
        },

        {
            path: "/inquiry/report",
            name: "InquiryReport",
            component: InquiryReport,
            meta: {
                groupName: "Inquiry",
                permissions: 'inquiry-reports',
                requiresAuth: true
            },
        },
    ],
};
let InquiryGroupsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Group",
    children: [
        {
            path: "inquiry/group",
            name: "GroupPage",
            component: GroupPage,
            meta: {
                groupName: "Group",
                permissions: 'inquiry-group-index',
                requiresAuth: true
            },
        },
    ],
};

let CyclesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Cycle",
    children: [{
        path: "/cycle/new-cycle",
        name: "NewCycle",
        component: NewCycle,
        meta: {
            groupName: "Cycle",
            permissions: 'cycle-create',
            requiresAuth: true
        },
    },
    {
        path: "/cycle/edit-cycle/:id",
        name: "EditCycle",
        component: EditCycle,
        meta: {
            groupName: "Cycle",
            permissions: 'cycle-update',
            requiresAuth: true
        },
    },
    {
        path: "/cycle/details-cycle/:id",
        name: "CycleDetails",
        component: DetailCycle,
        meta: {
            groupName: "Cycle",
            permissions: 'cycle-details',
            requiresAuth: true
        },
    },
    {
        path: "cycle/show",
        name: "CyclePage",
        component: CyclePage,
        meta: {
            groupName: "Cycle",
            permissions: 'cycle-index',
            requiresAuth: true
        },
    },
    ],
};
let OrdersPage = {
    path: "/",
    component: DashboardLayout,
    name: "Order",
    children: [
        {
            path: "/order/details-order/:id",
            name: "ApplicationDetails",
            component: DetailOrder,
            meta: {
                groupName: "Applications",
                permissions: 'order-details',
                requiresAuth: true
            },
        },
        {
            path: "order/show",
            name: "ApplicationsPage",
            component: OrderPage,
            meta: {
                groupName: "Applications",
                permissions: 'order-index',
                requiresAuth: true
            },
        },
    ],
};

let TicketsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Ticket",
    children: [
        {
            path: "ticket/ticket-subject",
            name: "TicketSubjectPage",
            component: TicketSubjectPage,
            meta: {
                groupName: "TicketSubject",
                permissions: 'ticket-subject-index',
                requiresAuth: true
            },
        },
        {
            path: "ticket/show",
            name: "TicketPage",
            component: TicketPage,
            meta: {
                groupName: "Ticket",
                permissions: 'ticket-index',
                requiresAuth: true
            },
        },
        {
            path: "ticket/details/:id",
            name: "DetailsTicket",
            component: DetailsTicket,
            meta: {
                groupName: "Ticket",
                permissions: 'ticket-details',
                requiresAuth: true
            },
        },
    ],
};
let AdminGroupsPage = {
    path: "/",
    component: DashboardLayout,
    name: "AdminGroup",
    children: [
        {
            path: "admin-group/show",
            name: "AdminGroupPage",
            component: AdminGroupPage,
            meta: {
                groupName: "AdminGroup",
                permissions: 'ticket-admin-group-index',
                requiresAuth: true
            },
        },
    ],
};

let Customer = {
    path: "/",
    component: DashboardLayout,
    name: "Customer",
    children: [{
        path: "/cycles",
        name: "Cycles",
        component: Cycles,
        meta: {
            groupName: "Applicant",
            requiresAuth: true
        },
    },

    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
            groupName: "Applicant",
            requiresAuth: true
        },
    },
    {
        path: "/orders",
        name: "Applications",
        component: Orders,
        meta: {
            groupName: "Applicant",
            requiresAuth: true
        },
    },
    {
        path: "/orders/:id/inqueries",
        name: "Inquiries",
        component: Inqueries,
        meta: {
            groupName: "Applicant",
            requiresAuth: true
        },
    },
    {
        path: "/tickets/view",
        name: "Tickets",
        component: Ticket,
        meta: {
            groupName: "Applicant",
            requiresAuth: true
        },
    },
    {
        path: "/tickets/detail/:id",
        name: "DetailTicket",
        component: DetailTicket,
        meta: {
            groupName: "Applicant",
            requiresAuth: true
        },
    },

    ],
};

let StagesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Stage",
    children: [
        {
            path: "stage/show",
            name: "StagePage",
            component: StagePage,
            meta: {
                groupName: "Stage",
                permissions: 'stage-index',
                requiresAuth: true
            },
        },
    ],
};


let Permissions = {
    path: "/",
    component: DashboardLayout,
    name: "Permissions-Page",
    children: [{
        path: "/permissions",
        name: "Permissions",
        component: () =>
            import("@/views/Permissions/index.vue"),
        meta: {
            groupName: "permissions",
        },
    },
    {
        path: "/permissions/create",
        name: "Create permission",
        component: () =>
            import("@/views/Permissions/create.vue"),
        meta: {
            groupName: "permissions",
        },
    },
    {
        path: "/permissions/:id",
        name: "Update permission",
        component: () =>
            import("@/views/Permissions/update.vue"),
        meta: {
            groupName: "permissions",
        },
    },
    ],
};
let GooglePage = {
    path: "/auth/google/callback",
    component: BlankLayout,
    name: "Google-Page",
    children: [{
        path: "/auth/google/callback",
        name: "Google",
        component: Google,
    },],
};
let RegisterForm = {
    path: "/",
    component: AuthBasicLayout,
    name: "Register-Form",
    children: [
        {
            path: "/form",
            name: "RegisterForm",
            component: Forms,
            meta: {
                groupName: "Applicant",
                requiresAuth: true,
                redirect: true
            },
        },
    ],
}
let Login = {
    path: "/",
    component: AuthCoverLayout,
    name: "Login-Page",
    children: [{
        path: "/login",
        name: "Login",
        component: LogIn,
    },
    {
        path: "/admin/login",
        name: "Login-Admin",
        component: LogInAdmin,
    },
    {
        path: "/reset_password",
        name: "Email",
        component: Email,
    },
    {
        path: "/reset",
        name: "ResetPassword",
        component: ResetPassword,
    },
    ],

};

let SignUp = {
    path: "/sign-up",
    component: AuthCoverLayout,
    name: "Signup-Page",
    children: [{
        path: "/sign-up",
        name: "Signup",
        component: Signup,
    },],
};

let Admins = {
    path: "/",
    component: DashboardLayout,
    name: "Admins-Page",
    children: [{
        path: "/users/admins",
        name: "Users",
        component: () =>
            import("@/views/Users/Admins/index.vue"),
        meta: {
            groupName: "Users",
            permissions: 'user-index',
            requiresAuth: true
        },
    },
    {
        path: "applicants",
        name: "ApplicantsPage",
        component: () =>
            import("@/views/Users/Customer/index.vue"),
        meta: {
            groupName: "Applicants",
            permissions: 'customer-index',
            requiresAuth: true
        },
    },
    {
        path: "/applicant/form",
        name: "FormRegisterPage",
        component: () =>
            import("@/views/Users/Customer/form.vue"),
        meta: {
            groupName: "Applicants",
            permissions: 'customer-register-form-index',
            requiresAuth: true
        },
    },
    {
        path: "/applicant/profile/:id",
        name: "ProfilePage",
        component: ProfileCustomer,
        meta: {
            groupName: "Applicant Profile",
            permissions: 'customer-profile',
            requiresAuth: true
        },
    },
    {
        path: "/applicant/create",
        name: "CreateCustomer",
        component: CreateCustomer,
        meta: {
            groupName: "Applicants",
            permissions: 'customer-create',
            requiresAuth: true
        },
    },


    {
        path: "/users/admins/create",
        name: "CreateUser",
        component: () =>
            import("@/views/Users/Admins/create.vue"),
        meta: {
            groupName: "Users",
            permissions: 'user-create',
            requiresAuth: true
        },
    },
    {
        path: "/users/admins/:id",
        name: "UpdateUser",
        component: () =>
            import("@/views/Users/Admins/update.vue"),
        meta: {
            groupName: "Users",
            permissions: 'user-update',
            requiresAuth: true
        },
    },
    ],
};

let Roles = {
    path: "/",
    component: DashboardLayout,
    name: "Roles-Managment",
    children: [{
        path: "/roles",
        name: "Roles",
        component: () =>
            import("@/views/Roles/index.vue"),
        meta: {
            groupName: "Roles",
            permissions: 'role-index',
            requiresAuth: true
        },
    },
    {
        path: "/roles/create",
        name: "Create Role",
        component: () =>
            import("@/views/Roles/create.vue"),
        meta: {
            groupName: "Roles",
            permissions: 'role-create',
            requiresAuth: true
        },
    },
    {
        path: "/roles/:id",
        name: "Update Role",
        component: () =>
            import("@/views/Roles/update.vue"),
        meta: {
            groupName: "Roles",
            permissions: 'role-update',
            requiresAuth: true
        },
    },
    ],
};

// cms
let SlidersPage = {
    path: "/",
    component: DashboardLayout,
    name: "Slide",
    children: [
        {
            path: "sliders/show",
            name: "SlidePage",
            component: SlidePage,
            meta: {
                groupName: "Slide",
                permissions: 'cms-slider-index',
                requiresAuth: true
            },
        },
    ],
};

let TeamsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Team",
    children: [
        {
            path: "teams/show",
            name: "TeamPage",
            component: TeamPage,
            meta: {
                groupName: "Team",
                permissions: 'cms-team-index',
                requiresAuth: true
            },
        },
    ],
};

let NewsesPage = {
    path: "/",
    component: DashboardLayout,
    name: "News",
    children: [
        {
            path: "news/show",
            name: "NewsPage",
            component: NewsPage,
            meta: {
                groupName: "News",
                permissions: 'cms-news-index',
                requiresAuth: true
            },
        },
        {
            path: "news/comments/:id",
            name: "NewsCommentsPage",
            component: NewsCommentsPage,
            meta: {
                groupName: "News",
                permissions: 'cms-news-comments',
                requiresAuth: true
            },
        },
        {
            path: "words",
            name: "BadWordsPage",
            component: BadWordsPage,
            meta: {
                groupName: "News",
                permissions: 'cms-word-reject-index',
                requiresAuth: true
            },
        },
    ],
};

let ProtfoliosPage = {
    path: "/",
    component: DashboardLayout,
    name: "Protfolio",
    children: [
        {
            path: "protfolio/show",
            name: "ProtfolioPage",
            component: ProtfolioPage,
            meta: {
                groupName: "Protfolio",
                permissions: 'cms-protfolio-index',
                requiresAuth: true
            },
        },
    ],
};

let QuotesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Quote",
    children: [
        {
            path: "quote/show",
            name: "QuotePage",
            component: QuotePage,
            meta: {
                groupName: "Quote",
                permissions: 'cms-quote-index',
                requiresAuth: true
            },
        },
    ],
};

let statisticsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Statistics",
    children: [
        {
            path: "statistics/show",
            name: "StatisticsPage",
            component: StatisticsPage,
            meta: {
                groupName: "Statistics",
                permissions: 'cms-statistic-index',
                requiresAuth: true
            },
        },
    ],
};

let PhilosophiesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Philosophies",
    children: [
        {
            path: "philosophies/show",
            name: "PhilosophiePage",
            component: PhilosophiePage,
            meta: {
                groupName: "Philosophies",
                permissions: 'cms-philosophies-index',
                requiresAuth: true
            },
        },
    ],
};

let ApproachsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Approach",
    children: [
        {
            path: "approach/index",
            name: "ApproachIndex",
            component: ApproachIndex,
            meta: {
                groupName: "Approach",
                permissions: 'cms-approach-index',
                requiresAuth: true
            },
        },
        {
            path: "approach/home",
            name: "ApproachHome",
            component: ApproachHome,
            meta: {
                groupName: "Approach",
                permissions: 'cms-approach-home',
                requiresAuth: true
            },
        },
    ],
};

let PageError = {
    path: "/error",
    component: AuthCoverLayout,
    name: "Errors",
    children: [{
        path: "/403",
        name: "403",
        component: () =>
            import("@/views/Error/403.vue"),
    },
        // {
        //     path: "/404",
        //     name: "404",
        //     component: () =>
        //         import("@/views/Error/403.vue"),
        // },
    ]
}

let vrPages = {
    path: "/",
    component: DashboardLayoutVr,
    name: "Vr",
    children: [{
        path: "/pages/dashboards/vr/vr-default",
        name: "VrDefault",
        component: VrDefault,
        meta: {
            groupName: "Dashboards",
        },
    },
    {
        path: "/pages/dashboards/vr/vr-info",
        name: "VrInfo",
        component: VrInfo,
        meta: {
            groupName: "Dashboards",
        },
    },
    ],
};

let profilePages = {
    path: "/",
    component: ProfileLayout,
    name: "Profiles",
    children: [{
        path: "/pages/pages/profile/overview",
        name: "ProfileOverview",
        component: ProfileOverview,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/profile/teams",
        name: "Teams",
        component: Teams,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/profile/projects",
        name: "Project",
        component: Projects,
        meta: {
            groupName: "Pages",
        },
    },
    ],
};

let userPages = {
    path: "/",
    component: DashboardLayout,
    name: "Users",
    children: [{
        path: "/pages/pages/users/reports",
        name: "Reports",
        component: Reports,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/users/new-user",
        name: "NewUser",
        component: NewUser,
        meta: {
            groupName: "Pages",
        },
    },
    ],
};

let accountPages = {
    path: "/",
    component: DashboardLayout,
    name: "Account",
    children: [{
        path: "/pages/pages/account/settings",
        name: "Settings",
        component: Settings,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/account/billing",
        name: "Billing",
        component: Billing,
        meta: {
            groupName: "Pages",
        },
    },
    {
        path: "/pages/pages/account/invoice",
        name: "Invoice",
        component: Invoice,
        meta: {
            groupName: "Pages",
        },
    },
    ],
};

let projectsPages = {
    path: "/",
    component: DashboardLayout,
    name: "Projects",
    children: [{
        path: "/pages/pages/projects/timeline",
        name: "Timeline",
        component: Timeline,
        meta: {
            groupName: "Pages",
        },
    },],
};

let applicationPages = {
    path: "/",
    component: DashboardLayout,
    name: "Application",
    children: [{
        path: "/pages/applications/kanban",
        name: "Kanban",
        component: Kanban,
        meta: {
            groupName: "Applications",
        },
    },
    {
        path: "/pages/applications/wizard",
        name: "Wizard",
        component: Wizard,
        meta: {
            groupName: "Applications",
        },
    },
    {
        path: "/pages/applications/datatables",
        name: "Datatables",
        component: Datatables,
        meta: {
            groupName: "Applications",
        },
    },
    {
        path: "/pages/applications/calendar",
        name: "Calendar",
        component: Calendar,
        meta: {
            groupName: "Applications",
        },
    },
    ],
};

let ecommercePages = {
    path: "/",
    component: ProfileLayout,
    name: "Ecommerce",
    children: [{
        path: "/pages/ecommerce/products/new-product",
        name: "NewProduct",
        component: NewProduct,
        meta: {
            groupName: "Ecommerce",
        },
    },],
};

let pricingPage = {
    path: "/",
    component: PageLayout,
    name: "Pricing Page",
    children: [{
        path: "/pages/pages/pricing-page",
        name: "Pricing",
        component: Pricing,
    },],
};

let authBasicPages = {
    path: "/",
    component: AuthBasicLayout,
    name: "Authentication Basic",
    children: [{
        path: "/pages/authentication/signup/basic",
        name: "SignUpBasic",
        component: SignUpBasic,
    },],
};

let authCoverPages = {
    path: "/",
    component: AuthCoverLayout,
    name: "Authentication Cover",
    children: [{
        path: "/pages/authentication/signup/cover",
        name: "SignUpCover",
        component: SignUpCover,
    },],
};

let authIllustrationPages = {
    path: "/",
    component: AuthIllustrationLayout,
    name: "Authentication Illustration",
    children: [{
        path: "/pages/authentication/signup/illustration",
        name: "SignUpIllustration",
        component: SignUpIllustration,
    },],
};

/************** Start Routes ***********************/

/************** End Routes ***********************/
const routes = [{
    path: "/",
    name: "Dashboard",
    redirect: store.state.auth.is_admin == 1 ? "/dashboard" : "/cycles",
    component: DashboardLayout,
    children: [{
        path: "dashboard",
        name: "Default",
        component: Dashboard,
        meta: {
            groupName: "Dashboard",
            permissions: 'dashboard',
            requiresAuth: true
        },
    },
    {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
            groupName: "Dashboards",
        },
    },
    {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
            groupName: "Dashboards",
        },
    },
    {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
            groupName: "Components",
        },
    },
    {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
            groupName: "Components",
        },
    },
    {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
            groupName: "Components",
        },
    },
    {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
            groupName: "Components",
        },
    },
    {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
            groupName: "Components",
        },
    },
    {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
            groupName: "Components",
        },
    },
    {
        path: "/pages/ecommerce/products/edit-product",
        name: "EditProduct",
        component: EditProduct,
        meta: {
            groupName: "Ecommerce",
        },
    },
    {
        path: "/pages/ecommerce/products/product-page",
        name: "ProductPage",
        component: ProductPage,
        meta: {
            groupName: "Ecommerce",
        },
    },
    {
        path: "/pages/ecommerce/orders/list",
        name: "OrderList",
        component: OrderList,
        meta: {
            groupName: "Ecommerce",
        },
    },
    {
        path: "/pages/ecommerce/orders/details",
        name: "OrderDetails",
        component: OrderDetails,
        meta: {
            groupName: "Ecommerce",
        },
    },

    ],
},
    InquiriesPage,
    InquiryGroupsPage,
    CyclesPage,
    StagesPage,
    OrdersPage,
    TicketsPage,
    statisticsPage,
    AdminGroupsPage,
    Permissions,
    Admins,
    Roles,
    Customer,
    PageError,
    SlidersPage,
    TeamsPage,
    NewsesPage,
    ProtfoliosPage,
    PhilosophiesPage,
    QuotesPage,
    ApproachsPage,
    pricingPage,
    profilePages,
    applicationPages,
    ecommercePages,
    userPages,
    accountPages,
    projectsPages,
    vrPages,
    authBasicPages,
    authCoverPages,
    authIllustrationPages,
    Login,
    RegisterForm,
    GooglePage,
    SignUp,

{
    path: "*",
    component: AuthCoverLayout,
    name: "Error",
    redirect: "/404",
    children: [{
        path: "/404",
        name: "404",
        component: () =>
            import("@/views/Error/404.vue"),
    },]
},
    //     {
    //     path: '*',
    //     component: () =>
    //         import("@/views/Error/404.vue"),
    // }
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;