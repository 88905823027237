import StageService from '../services/stage.service';
import i18n from '@/i18n.js'
export const stage = {
    namespaced: true,
    state: () => ({
        stages: [],
    }),
    actions: {

        getStages({ state, rootState }, data) {
            return StageService.get_stages(data.page, data.data).then(
                res => {
                    //console.log(res)
                    state.stages = res.data.stages.data;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addStage({ rootState, state }, data) {
            return StageService.add_stage(data.data).then(
                res => {
                    //console.log(res)
                    state.stages = state.stages.concat(res.data.stage)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editStage({ rootState, state }, data) {
            return StageService.edit_stage(data.id, data.data).then(
                res => {
                    //console.log(res)
                    state.stages = state.stages.filter(v => v.id != data.id)
                    state.stages = state.stages.concat(res.data.stage)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyStage({ state }, id) {
            return StageService.destroy_stage(id).then(
                res => {
                    // console.log(response)
                    state.stages = state.stages.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    // console.log(error)
                    return Promise.reject(error);
                }
            );
        },
    },
};