class InquiryService {
  get_lists(page, data) {
    return axios.post('inquires?page=' + page, data)
  }
  get_list(id) {
    return axios.get('inquires/edit/' + id)
  }
  add_list(data) {
    return axios.post('inquires/store', data)
  }
  edit_list(id, data) {
    return axios.post('inquires/update/' + id, data)
  }
  destroy_list(id) {
    return axios.delete('inquires/delete/' + id)
  }
  get_inputs() {
    return axios.get('inputs')
  }
  get_inqieries() {
    return axios.get('groups/inqieries')
  }

  get_groups() {
    return axios.get('groups')
  }
  get_group(id) {
    return axios.get('groups/edit/' + id)
  }
  add_group(data) {
    return axios.post('groups/store', data)
  }
  edit_group(id, data) {
    return axios.post('groups/update/' + id, data)
  }
  destroy_group(id) {
    return axios.delete('groups/delete/' + id)
  }

  inqieries_report(page, data) {
    return axios.post('inquires/report?page=' + page, data)
  }
}
export default new InquiryService();