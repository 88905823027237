class CmsService {
  get_sliders() {
    return axios.get('cms/slider')
  }
  add_slider(data) {
    return axios.post('cms/slider/store', data, { headers: { "Content-type": "multipart/form-data" } })
  }
  edit_slider(id, data) {
    return axios.post('cms/slider/update/' + id, data, { headers: { "Content-type": "multipart/form-data" } })
  }
  destroy_slider(id) {
    return axios.delete('cms/slider/delete/' + id)
  }

  // team section
  get_teams() {
    return axios.get('cms/teams')
  }
  add_team(data) {
    return axios.post('cms/teams/store', data, { headers: { "Content-type": "multipart/form-data" } },)
  }
  edit_team(id, data) {
    return axios.post('cms/teams/update/' + id, data, { headers: { "Content-type": "multipart/form-data" } })
  }
  destroy_team(id) {
    return axios.delete('cms/teams/delete/' + id)
  }

  // philosophy
  get_philosophys(page, data) {
    return axios.post('cms/philosophy?page=' + page, data)
  }
  add_philosophy(data) {
    return axios.post('cms/philosophy/store', data)
  }
  edit_philosophy(id, data) {
    return axios.post('cms/philosophy/update/' + id, data)
  }
  destroy_philosophy(id) {
    return axios.delete('cms/philosophy/delete/' + id)
  }


  // approach home
  get_approach() {
    return axios.get('cms/approach')
  }
  add_approach(data) {
    return axios.post('cms/approach/store', data)
  }
  edit_approach(id, data) {
    return axios.post('cms/approach/update/' + id, data)
  }
  destroy_approach(id) {
    return axios.delete('cms/approach/delete/' + id)
  }
  edit_paragraph_approach(id, data) {
    return axios.post('cms/approach_paragraph/update/' + id, data)
  }
  get_paragraph_approach() {
    return axios.get('cms/approach_paragraph')
  }

  // word
  get_words() {
    return axios.get('cms/badwords')
  }
  add_word(data) {
    return axios.post('cms/badwords/store', data)
  }
  edit_word(id, data) {
    return axios.post('cms/badwords/update/' + id, data)
  }
  destroy_word(id) {
    return axios.delete('cms/badwords/delete/' + id)
  }

  // news section
  get_news(page, data) {
    return axios.post('cms/news?page=' + page, data)
  }
  add_news(data) {
    return axios.post('cms/news/store', data, { headers: { "Content-type": "multipart/form-data" } },)
  }
  edit_news(id, data) {
    return axios.post('cms/news/update/' + id, data, { headers: { "Content-type": "multipart/form-data" } })
  }
  destroy_news(id) {
    return axios.delete('cms/news/delete/' + id)
  }
  approve_comment(id) {
    return axios.post('cms/news/approve/' + id)
  }
  reject_comment(id) {
    return axios.post('cms/news/reject/' + id)
  }
  get_news_comments(id) {
    return axios.get('cms/news/comments/' + id)
  }

  // protfolio
  get_protfolio() {
    return axios.get('cms/protfolio')
  }
  add_protfolio(data) {
    return axios.post('cms/protfolio/store', data)
  }
  edit_protfolio(id, data) {
    return axios.post('cms/protfolio/update/' + id, data)
  }
  destroy_protfolio(id) {
    return axios.delete('cms/protfolio/delete/' + id)
  }

  // quote
  get_quotes() {
    return axios.get('cms/quotes')
  }
  edit_quote(id, data) {
    return axios.post('cms/quotes/update/' + id, data)
  }

  // statistics
  get_statistics() {
    return axios.get('cms/statistics')
  }
  edit_statistic(id, data) {
    return axios.post('cms/statistics/update/' + id, data)
  }
}
export default new CmsService();