import InquiryService from '../services/inquiry.service'
import i18n from '@/i18n.js'
export const inquiry = {
    namespaced: true,
    state: () => ({
        // group section 
        groups: [],
        inqieries: [],
        inquiries_data: [],

        // inquiry section
        inquiries: [],
    }),
    actions: {
        getGroups({ state, rootState }) {
            return InquiryService.get_groups().then(
                res => {
                    //console.log(res)
                    state.groups = res.data.groups
                    for (let i = 0; i < state.groups.length; i++) {
                        const element = state.groups[i];
                        state.inquiries_data[element.id] = element.inqieries
                    }
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },
        addGroup({ rootState, state }, data) {
            return InquiryService.add_group(data.data).then(
                res => {
                    //console.log(res)
                    state.groups = state.groups.concat(res.data.group)
                    state.inquiries_data[res.data.group.id] = res.data.group.inqieries
                    state.inqieries = []
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        UpdateGroup({ rootState, state }, data) {
            return InquiryService.edit_group(data.id, data.data).then(
                res => {
                    //console.log(res)
                    state.groups = state.groups.filter(v => v.id != data.id)
                    state.groups = state.groups.concat(res.data.group)
                    state.inquiries_data[res.data.group.id] = res.data.group.inqieries
                    state.inqieries = []
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyGroup({ state }, id) {
            return InquiryService.destroy_group(id).then(
                res => {
                    state.groups = state.groups.filter(v => v.id != id)
                    state.inqieries = []
                    // console.log(state.inquiries_data)
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        getInqieries({ state }, id) {
            state.inqieries = state.inquiries_data[id]
        },

        // inquiry list
        getInquiries({ state, rootState }, data) {
            return InquiryService.get_lists(data.page, data.data).then(
                res => {
                    //console.log(res)
                    state.inquiries = res.data.inqueries.data;
                    return Promise.resolve(res);
                },
                error => {
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },
        addInquiry({ rootState, state }, data) {
            return InquiryService.add_list(data.data).then(
                res => {
                    //console.log(res)
                    state.inquiries = state.inquiries.concat(res.data.inquiry)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        editInquiry({ rootState, state }, data) {
            return InquiryService.edit_list(data.id, data.data).then(
                res => {
                    //console.log(res)
                    state.inquiries = state.inquiries.filter(v => v.id != data.id)
                    state.inquiries = state.inquiries.concat(res.data.inquiry)
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            // console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyInquiry({ state }, id) {
            return InquiryService.destroy_list(id).then(
                res => {
                    // console.log(response)
                    state.inquiries = state.inquiries.filter(v => v.id != id)
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },
    },
};